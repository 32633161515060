import React, { useState, useEffect } from "react";
import "./cashtransaction.css";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import PageHeader from "../Components/PageHeader";
import Card from "../Components/Card";
import useGetClientMasterList from "../hooks/ClientMaster/useGetClientMasterList";
import useGetCashData from "../hooks/useGetCashData";
import useCreateCashLedger from "../hooks/useCreateCashLedger";
import { dateFormatter } from "../utils/dateFormatter";
import Select from "react-select";
import useGetAccountStatus from "../hooks/useGetAccountStatus";

export default function TotalComission(props) {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: getCashData } = useGetCashData();
  const { data: ClientMasterListData } = useGetClientMasterList();
  const { mutate: createCashLedger } = useCreateCashLedger();
  const { mutate: accountStatus } = useGetAccountStatus();

  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [cashData, setCashData] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const loggedAccount = localStorage.getItem("account");

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function transformData(data) {
      return data.map((item) => {
        return { value: item.id, label: `${item.code} ${item.name}` };
      });
    }

    if (ClientMasterListData !== undefined) {
      const listData = ClientMasterListData?.data;

      if (listData.length !== 0) {
        listData?.sort((a, b) => {
          return +new Date(b.createdAt) - +new Date(a.createdAt);
        });
        const transformedData = transformData(listData);
        setData(transformedData);
      }
    }
  }, [ClientMasterListData]);

  function fetchCashData(selected) {
    //console.log("selected", selected);
    //console.log(loggedAccount);

    const payload = {
      user_id: selected,
      acc_type: "user",
      logged_acc_type: String(loggedAccount),
    };

    getCashData(payload, {
      onSuccess: (response) => {
        //console.log("response", response);
        let updatedCashData = [];

        response.data.forEach((element) => {
          if (element.game != null && element.game.length === 0) {
            const existingIndex = cashData.findIndex(
              (el) => el.match_id === element.match_id
            );

            if (existingIndex !== -1) {
              updatedCashData.push({
                ...element,
                balance: cashData[existingIndex].balance + element.final,
              });
            } else {
              updatedCashData.push({
                ...element,
                balance: element.final,
              });
            }
          } else {
            updatedCashData.push({
              ...element,
              balance: 0,
            });
          }
        });

        updatedCashData.reverse();

        for (let index = 0; index < updatedCashData.length; index++) {
          if (index === 0) {
            updatedCashData[index].balance = Math.round(
              updatedCashData[index].final
            );
          } else {
            updatedCashData[index].balance =
              Math.round(updatedCashData[index - 1].balance) +
              Math.round(updatedCashData[index].final);
          }
        }

        updatedCashData.reverse();

        let totalAmount = 0;
        let debitAmount = 0;
        let creditAmount = 0;

        updatedCashData.forEach((element) => {
          if (element.final > 0) {
            debitAmount += element.final;
          } else {
            creditAmount += element.final;
          }
        }, 0);

        totalAmount = creditAmount + debitAmount;

        setTotalDebit(Math.round(debitAmount));
        setTotalCredit(Math.round(creditAmount));
        setTotalBalance(Math.round(totalAmount));

        setCashData(updatedCashData);

        //console.log("cashData", updatedCashData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    const payload = {
      ...data,
    };

    if (payload.type === "lena") {
      payload.amount = payload.amount * -1;
    }

    const createPayload = {
      acc_type: "user",
      user_id: selectedUser,
      amount: payload.amount,
      match_decision: 1,
      remarks: payload.remark,
    };

    createCashLedger(createPayload, {
      onSuccess: (response) => {
        //console.log("response", response);
        form.reset();
        setCashData([]);
        toast.success("Ledger Created Successfully!");
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }

  return (
    <div className="content-wrapper">
      <PageHeader
        title="Commission Len Den
"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <Card header="Ledger">
              <div className="table-responsive pl-4 pr-4">
                <table
                  id="datatable"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ fontWeight: "700" }}>
                        CODE <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        M Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        S Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        Total Comm <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        M Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        S Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        Total Comm <span id="sorted-icon">↑↓</span>
                      </th>
                      <th>Comm Bacha</th>
                    </tr>
                    <tr>
                      <th>#</th>

                      <th
                        colSpan={4}
                        style={{ color: "green", fontSize: "16px" }}
                      >
                        UPER SE MILA
                      </th>

                      <th
                        colSpan={3}
                        style={{ color: "red", fontSize: "16px" }}
                      >
                        CLIENT KO DIYA
                      </th>
                      <th style={{ color: "blue", fontSize: "16px" }}>
                        TOTAL BACHA{" "}
                        <span id="sorted-icon" style={{ color: "blue" }}>
                          ↑↓
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashData.length !== 0 ? (
                      <>
                        <tr className="fw-bold">
                          <td style={{ height: "40px" }}></td>
                          <td style={{}}></td>
                          <td
                            colSpan="1"
                            className={`text-center ${
                              totalBalance > 0 ? "text-primary" : "text-danger"
                            }`}
                            style={{ fontWeight: "bold" }}
                          >
                            Total Amount
                          </td>
                          <td
                            className="text-black"
                            style={{ fontWeight: "bold" }}
                          >
                            {totalDebit}
                          </td>
                          <td
                            className="text-black"
                            style={{ fontWeight: "bold" }}
                          >
                            {totalCredit}
                          </td>
                          <td
                            style={{ fontWeight: "bold" }}
                            className={
                              totalBalance > 0 ? "text-primary" : "text-danger"
                            }
                          >
                            {totalBalance}
                          </td>
                          <td></td>
                        </tr>
                        {cashData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ height: "60px" }}>{index + 1}</td>
                              <td style={{ height: "60px" }}>
                                {item.game
                                  ? dateFormatter(item.game.createdAt)
                                  : dateFormatter(item.createdAt)}
                              </td>
                              <td style={{ height: "60px" }}>
                                {item.game ? "CA1 CASH" : "Cash Pay"}
                              </td>
                              <td
                                className="text-black"
                                style={{ height: "60px" }}
                              >
                                {item.final > 0 ? Math.round(item.final) : 0}
                              </td>
                              <td
                                className="text-black"
                                style={{ height: "60px" }}
                              >
                                {item.final < 0 ? Math.round(item.final) : 0}
                              </td>
                              <td
                                style={{ height: "60px" }}
                                className={
                                  item.balance > 0 ? "text-black" : "text-black"
                                }
                              >
                                {item.balance}
                              </td>
                              <td style={{ height: "40px" }}></td>
                              <td style={{ height: "40px" }}>
                                {item.game ? item.game.name : item.remarks}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No User Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
