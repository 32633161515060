import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "../../Components/Card";
import PageHeader from "../../Components/PageHeader";
import useGetMyLedger from "../../hooks/useGetMyLedger";
import { dateFormatter, dateTimeFormatter } from "../../utils/dateFormatter";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function MyLedger() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();

  const { mutate: getMyLedger } = useGetMyLedger();
  const [data, setData] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  useEffect(() => {
    const payload = {
      user_id: localStorage.getItem("UUID"),
      logged_acc_type: localStorage.getItem("account"),
    };

    getMyLedger(payload, {
      onSuccess: (response) => {
        //console.log(response);
        // setData(response.data);

        let updatedData = [];

        response.data.forEach((element) => {
          if (element.games != null) {
            const existingIndex = updatedData.findIndex(
              (el) => el.match_id === element.match_id
            );

            if (existingIndex !== -1) {
              // updatedData.push({
              //   ...element,
              //   balance: data[existingIndex]?.balance + element.total_final,
              // });
              console.log("existingIndex", existingIndex);
              updatedData[existingIndex].total_final =
                element.total_final + updatedData[existingIndex].total_final;
            } else {
              updatedData.push({
                ...element,
                balance: element.total_final,
              });
            }
          } else {
            updatedData.push({
              ...element,
              balance: 0,
            });
          }
        });

        updatedData?.sort((a, b) => {
          return (
            new Date(b.games[0]?.createdAt || b.createdAt) -
            new Date(a.games[0]?.createdAt || a.createdAt)
          );
        });

        updatedData.reverse();

        for (let index = 0; index < updatedData.length; index++) {
          if (index === 0) {
            updatedData[index].balance = roundToTwoDecimals(
              updatedData[index].total_final
            );
          } else {
            updatedData[index].balance = roundToTwoDecimals(
              updatedData[index - 1].balance + updatedData[index].total_final
            );
          }
        }

        updatedData.reverse();

        let totalAmount = 0;
        let debitAmount = 0;
        let creditAmount = 0;

        updatedData.forEach((element) => {
          if (element.total_final > 0) {
            debitAmount += element.total_final;
          } else {
            creditAmount += element.total_final;
          }
        }, 0);

        totalAmount = creditAmount + debitAmount;

        setTotalDebit(roundToTwoDecimals(debitAmount));
        setTotalCredit(roundToTwoDecimals(creditAmount));
        setTotalBalance(roundToTwoDecimals(totalAmount));

        setData(updatedData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>My Ledger</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>My Ledger</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="card p-2">
            <div className="table-responsive">
              <table
                id="datatable"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px", height: "57px" }}>#</th>
                    <th style={{ minWidth: "80px", height: "57px" }}>Date</th>
                    <th style={{ minWidth: "123px", height: "57px" }}>
                      Collection Name
                    </th>
                    <th style={{ minWidth: "58px", height: "57px" }}>Debit</th>
                    <th style={{ minWidth: "59px", height: "57px" }}>Credit</th>
                    <th style={{ minWidth: "69px", height: "57px" }}>
                      Balance
                    </th>
                    <th style={{ minWidth: "110px", height: "57px" }}>
                      Payment Type
                    </th>
                    <th style={{ minWidth: "363px", height: "57px" }}>
                      Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length !== 0 ? (
                    <>
                      <tr className="fw-bold">
                        <td
                          style={{ height: "40px" }}
                          colSpan="3"
                          className={`text-center text-lg fw-bold ${
                            totalBalance > 0 ? "text-primary" : "text-danger"
                          }`}
                        >
                          Total Amount
                        </td>

                        <td className="text-black text-lg fw-bold">
                          {totalDebit}
                        </td>
                        <td className="text-black text-lg fw-bold">
                          {totalCredit}
                        </td>
                        <td
                          style={{ height: "55px" }}
                          className={
                            totalBalance > 0
                              ? "text-primary fw-bold text-lg"
                              : "text-danger fw-bold text-lg"
                          }
                        >
                          {totalBalance}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      {data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ height: "60px" }}>{index + 1}</td>
                            <td>
                              {item?.games
                                ? dateTimeFormatter(item?.games[0]?.createdAt)
                                : dateTimeFormatter(item?.createdAt)}
                            </td>
                            <td>{item?.games ? "CA1 CASH" : "Cash Pay"}</td>
                            <td className="text-black">
                              {item?.total_final > 0
                                ? roundToTwoDecimals(item?.total_final)
                                : 0}
                            </td>
                            <td className="text-black">
                              {item?.total_final < 0
                                ? roundToTwoDecimals(item?.total_final)
                                : 0}
                            </td>
                            <td
                              className={
                                item?.balance > 0 ? "text-black" : "text-black"
                              }
                            >
                              {item?.balance}
                            </td>
                            <td></td>
                            <td>
                              {item?.games
                                ? item?.games[0]?.name
                                : item?.remarks}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No User Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
