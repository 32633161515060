import React, { useEffect, useState } from "react";
import "./SuperMasterLimitUpdate.css";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import useGetSuperAdminList from "../../hooks/SuperAdmin/useGetSuperAdminList";
import useGetParent from "../../hooks/useGetParent";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAdminAccount from "../../hooks/useGetAdminAccount";
import { useQueryClient } from "react-query";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function SuperMasterLimitUpdate() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();

  const queryClient = useQueryClient();

  const { data: adminData } = useGetAdminAccount();
  const { data: SuperAdminListData } = useGetSuperAdminList();
  const { mutate: getParent } = useGetParent();
  const { mutate: updateLimit } = useUpdateLimit();
  const [data, setData] = useState([]);
  const [parentData, setParentData] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listData = SuperAdminListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
    }
  }, [SuperAdminListData]);

  useEffect(() => {
    setParentData(adminData?.data.data[0]);
  }, [adminData]);

  const handleAddLimit = (id, superAdminLimit) => {
    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value >= parentData.limit) {
      toast.error(
        "You can not add balance greater than your Parent limit balance"
      );
      inputElement.value = "";
      return;
    }

    const superAdminLimitPayload = {
      user_id: id,
      acc_type: "super_admin",
      limit: Number(superAdminLimit) + Number(value),
      coins: value,
      types: "credit",
      description: "Super Admin Limit Update",
    };

    const parentLimitPayload = {
      user_id: parentData.id,
      acc_type: "admin",
      limit: Number(parentData.limit) - Number(value),
      coins: value,
      types: "debit",
      description: "Admin Limit Update",
    };

    updateLimit(parentLimitPayload, {
      onSuccess: () => {
        updateLimit(superAdminLimitPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries("get-super-admin-list");
            toast.success("Limit Added Successfully");

            inputElement.value = "";
          },
          oonError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const handleMinusLimit = (id, superAdminLimit) => {
    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value > superAdminLimit) {
      toast.error("You can not minus balance greater than your balance");
      inputElement.value = "";
      return;
    }

    const superAdminLimitPayload = {
      user_id: id,
      acc_type: "super_admin",
      limit: Number(superAdminLimit) - Number(value),
      coins: value,
      types: "debit",
      description: "Super Admin Limit Update",
    };

    const parentLimitPayload = {
      user_id: parentData.id,
      acc_type: "admin",
      limit: Number(parentData.limit) + Number(value),
      coins: value,
      types: "credit",
      description: "Admin Limit Update",
    };

    updateLimit(superAdminLimitPayload, {
      onSuccess: () => {
        updateLimit(parentLimitPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries("get-super-admin-list");
            toast.success("Limit Minus Successfully");

            inputElement.value = "";
          },
          oonError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Super Admin</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/supermaster">Super Admin</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Limit Update</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content" style={{ overflowX: "auto" }}>
        <div className="container-fluid">
          <div className="col-6">
            <div className="card" style={{ width: "730px" }}>
              <div className="card-header bg-primary">
                <h5 className="text-white mb-0">Super Admin Coin Details</h5>
              </div>

              <div className="card-body">
                <div className="">
                  <table
                    className="table table-bordered table-striped table-responsive"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Super Admin Name</th>
                        <th>Super Admin Limit</th>
                        <th>Enter Limit</th>
                        <th>My Limit: {parentData?.limit}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.name} ({item.code})
                          </td>
                          <td id="limit-update1">{item.limit}</td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Limit"
                              min="0"
                              id={item.id}
                              style={{ width: "200px" }}
                            />
                          </td>
                          <td
                            className="action-btn-container"
                            id="agent-plus-minus-box1"
                          >
                            <Link
                              className="btn btn-primary mr-2 action-btns action-button-plus-minus"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddLimit(item.id, item.limit);
                              }}
                            >
                              Add
                            </Link>
                            <Link
                              className="btn btn-danger action-btns action-button-plus-minus"
                              onClick={(e) => {
                                e.preventDefault();
                                handleMinusLimit(item.id, item.limit);
                              }}
                            >
                              Minus
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
