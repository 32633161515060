import React from "react";
import "./CreatePowerUsers.css";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreatePowerUsers from "../../hooks/PowerUsers/useCreatePowerUsers";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function CreatePowerUsers() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();
  const navigate = useNavigate();
  const { mutate: createPowerUser } = useCreatePowerUsers();
  const [powerUserName, setPowerUserName] = useState("");
  const [powerUserNumber, setPowerUserNumber] = useState("");
  const [powerUserPassword, setPowerUserPassword] = useState("");

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPowerUserPassword(passwordGenerator());
  }, []);

  const generateNewPassword = () => {
    setPowerUserPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      name: powerUserName,
      mobile: powerUserNumber,
      password: String(powerUserPassword),
      acc_type: "power_user",
    };

    createPowerUser(payload, {
      onSuccess: () => {
        navigate("/power-users");
        toast.success("Power User created successfully!");
      },
      onError: (error) => {
        toast.error("Something went wrong! Please try again later.");
        console.error(error);
      },
    });
  }

  const [isOpen, setIsOpen] = useState(true);
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create New Power User </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/power-users">Power Users</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/power-users">Back</Link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${
                        isOpen ? "" : "collapsed"
                      }`}
                      id="create-button-1"
                      type="button"
                      onClick={toggleAccordion}
                      aria-expanded={isOpen}
                      aria-controls="collapseOne"
                    >
                      General
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={`accordion-collapse collapse ${
                      isOpen ? "show" : ""
                    }`}
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="powerUserCode">
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="powerUserCode"
                            name="powerUserCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="powerUserName"
                            name="powerUserName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setPowerUserName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="powerUserNumber"
                          >
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="powerUserNumber"
                            name="powerUserNumber"
                            className="form-control"
                            placeholder="Enter Contact Number"
                            minLength={10}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setPowerUserNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="powerUserPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="powerUserPassword"
                              name="powerUserPassword"
                              className="form-control"
                              required
                              value={powerUserPassword}
                              onChange={(e) => {
                                setPowerUserPassword(e.target.value);
                              }}
                            />
                            <span
                              className="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="mt-2 btn btn-sm btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
