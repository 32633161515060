import React, { useEffect, useState } from "react";
import "./MasterLimitUpdate.css";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import { Link } from "react-router-dom";
import useGetMasterList from "../../hooks/Master/useGetMasterList";
import useGetParent from "../../hooks/useGetParent";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import { useQueryClient } from "react-query";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function MasterLimitUpdate() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();
  const queryClient = useQueryClient();

  const { data: MasterListData } = useGetMasterList();
  const { mutate: getParent } = useGetParent();
  const { mutate: updateLimit } = useUpdateLimit();
  const [data, setData] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listData = MasterListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
    }
  }, [MasterListData]);

  const handleAddLimit = (id) => {
    const payload = {
      acc_type: "master",
      user_id: id,
    };

    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    getParent(payload, {
      onSuccess: (data) => {
        if (value >= data.data.data.parents.limit) {
          toast.error(
            "You can not add balance greater than your Parent limit balance"
          );
          inputElement.value = "";
          return;
        }

        const masterLimitPayload = {
          user_id: data.data.data.id,
          acc_type: "master",
          limit: Number(data.data.data.limit) + Number(value),
          coins: value,
          types: "credit",
          description: "Master Limit Update",
        };

        const parentLimitPayload = {
          user_id: data.data.data.parents.id,
          acc_type: "sub_admin",
          limit: Number(data.data.data.parents.limit) - Number(value),
          coins: value,
          types: "debit",
          description: "Sub Admin Limit Update",
        };

        updateLimit(parentLimitPayload, {
          onSuccess: () => {
            updateLimit(masterLimitPayload, {
              onSuccess: () => {
                queryClient.invalidateQueries("get-master-list");
                toast.success("Limit Added Successfully");

                inputElement.value = "";
              },
              oonError: (error) => {
                console.error(error);
              },
            });
          },
          onError: (error) => {
            console.error(error);
          },
        });
      },

      onError: (error) => {
        toast.error(error);
      },
    });
  };

  const handleMinusLimit = (id, masterLimit) => {
    const payload = {
      acc_type: "master",
      user_id: id,
    };

    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    getParent(payload, {
      onSuccess: (data) => {
        if (value > masterLimit) {
          toast.error("You can not minus balance greater than your balance");
          inputElement.value = "";
          return;
        }

        const masterLimitPayload = {
          user_id: data.data.data.id,
          acc_type: "master",
          limit: Number(data.data.data.limit) - Number(value),
          coins: value,
          types: "debit",
          description: "Master Limit Update",
        };

        const parentLimitPayload = {
          user_id: data.data.data.parents.id,
          acc_type: "sub_admin",
          limit: Number(data.data.data.parents.limit) + Number(value),
          coins: value,
          types: "credit",
          description: "Sub Admin Limit Update",
        };

        updateLimit(masterLimitPayload, {
          onSuccess: () => {
            updateLimit(parentLimitPayload, {
              onSuccess: () => {
                queryClient.invalidateQueries("get-master-list");
                toast.success("Limit Minus Successfully");

                inputElement.value = "";
              },
              oonError: (error) => {
                console.error(error);
              },
            });
          },
          onError: (error) => {
            console.error(error);
          },
        });
      },

      onError: (error) => {
        toast.error(error);
      },
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Master</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/masters">Master</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Limit Update</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content" style={{ overflowX: "auto" }}>
        <div className="container-fluid">
          <div className="col-6">
            <div className="card" style={{ width: "655px" }}>
              <div className="card-header bg-primary">
                <h5 className="text-white mb-0">Master Coin Details</h5>
              </div>

              <div className="card-body">
                <div className="">
                  <table
                    className="table table-bordered table-striped table-responsive"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Master Name</th>
                        <th>Master Limit</th>
                        <th>Enter Limit</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.name} ({item.code})
                          </td>
                          <td>{item.limit}</td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Limit"
                              min="0"
                              id={item.id}
                              style={{ width: "200px" }}
                            />
                          </td>
                          <td
                            className="action-btn-container"
                            id="agent-plus-minus-box1"
                          >
                            <Link
                              className="btn btn-primary mr-2 action-btns action-button-plus-minus"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddLimit(item.id);
                              }}
                            >
                              Add
                            </Link>
                            <Link
                              className="btn btn-danger action-btns action-button-plus-minus"
                              onClick={(e) => {
                                e.preventDefault();
                                handleMinusLimit(item.id, item.limit);
                              }}
                            >
                              Minus
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
