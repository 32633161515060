/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./InplayMatchBets.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import useDeleteBets from "../../hooks/Games/Inplay/useDeleteBets";
import useDeleteMatchBet from "../../hooks/Games/Inplay/useDeleteMatchBet";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Select from "react-select";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
// import TimePicker from "react-time-picker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import useEditMatchBet from "../../hooks/Games/Inplay/useEditMatchBet";

export default function InplayMatchBets() {
  // const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userCode = localStorage.getItem("CODE");

  const { mutate: accountStatus } = useGetAccountStatus();
  const location = useLocation();
  const navigate = useNavigate();
  const UrlSearchParams = new URLSearchParams(location.search);

  let teams = location.state;

  const { mutate: getBetsData } = useGetBetsData();
  const { mutate: deleteBets } = useDeleteBets();
  const { mutate: deleteMatchBet } = useDeleteMatchBet();
  // const { mutate: editMatchBet } = useEditMatchBet();

  const matchId = UrlSearchParams.get("id");
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  const [matchBets, setMatchBets] = useState([]);
  const [allBets, setAllBets] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [onDate, setOnDate] = useState("");
  const [fromTime, setFromTime] = useState("10:00");
  const [toTime, setToTime] = useState("");
  const [users, setUsers] = useState([]);
  const [team1Total, setTeam1Total] = useState(0);
  const [team2Total, setTeam2Total] = useState(0);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetData = () => {
    const payload = {
      match_id: matchId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getBetsData(payload, {
      onSuccess: (response) => {
        let userData = [];

        response.data.forEach((el) => {
          if (!userData.some((ele) => ele.id === el.user_accounts[0].id)) {
            userData.push({
              id: el.user_accounts[0].id,
              name: `${el.user_accounts[0].name}`,
              code: el.user_accounts[0].code,
            });
          }
        });

        setUsers(userData);

        let filteredData = response.data;

        calculateBets(filteredData);

        setMatchBets(filteredData);
        setAllBets(filteredData);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (matchId !== undefined && matchId !== null) {
      handleGetData();
    } else {
      navigate("/inplay");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateBets = (bets) => {
    let team1Bets = 0;
    let team2Bets = 0;

    if (bets.length > 0) {
      bets?.forEach((bets) => {
        if (bets.betTeam === "A" && bets.type === "lay") {
          team1Bets = team1Bets + bets.teamA_lay_amount;
          team2Bets = team2Bets + bets.teamB_lay_amount;
        }

        if (bets.betTeam === "A" && bets.type === "back") {
          team1Bets = team1Bets + bets.teamA_bet_amount;
          team2Bets = team2Bets + bets.teamB_bet_amount;
        }

        if (bets.betTeam === "B" && bets.type === "back") {
          team1Bets = team1Bets + bets.teamA_bet_amount;
          team2Bets = team2Bets + bets.teamB_bet_amount;
        }

        if (bets.betTeam === "B" && bets.type === "lay") {
          team1Bets = team1Bets + bets.teamA_lay_amount;
          team2Bets = team2Bets + bets.teamB_lay_amount;
        }
      });

      setTeam1Total(Math.round(team1Bets));
      setTeam2Total(Math.round(team2Bets));
    }
  };

  function changeUser(value) {
    if (value === "all" || value === "") {
      setMatchBets(allBets);
    } else {
      const filteredBets = allBets.filter(
        (bet) => bet.user_accounts[0].id === value
      );

      let team1Bets = 0;
      let team2Bets = 0;

      if (filteredBets.length > 0) {
        filteredBets?.forEach((bets) => {
          if (bets.betTeam === "A" && bets.type === "lay") {
            team1Bets = team1Bets + bets.teamA_lay_amount;
            team2Bets = team2Bets + bets.teamB_lay_amount;
          }

          if (bets.betTeam === "A" && bets.type === "back") {
            team1Bets = team1Bets + bets.teamA_bet_amount;
            team2Bets = team2Bets + bets.teamB_bet_amount;
          }

          if (bets.betTeam === "B" && bets.type === "back") {
            team1Bets = team1Bets + bets.teamA_bet_amount;
            team2Bets = team2Bets + bets.teamB_bet_amount;
          }

          if (bets.betTeam === "B" && bets.type === "lay") {
            team1Bets = team1Bets + bets.teamA_lay_amount;
            team2Bets = team2Bets + bets.teamB_lay_amount;
          }
        });

        setTeam1Total(Math.round(team1Bets));
        setTeam2Total(Math.round(team2Bets));
      }

      setMatchBets(filteredBets);
    }
  }

  function handleDeleteBets(e) {
    e.preventDefault();
    const fromDate = String(onDate + " " + fromTime);
    const toDate = String(onDate + " " + toTime);

    const payload = {
      user_id: selectedUser,
      from_date: fromDate,
      to_date: toDate,
      type: "match",
      match_id: matchId,
    };

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete all bets from ${fromDate} to ${toDate}. You won't be able to revert it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@9ex") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBets(payload, {
          onSuccess: (response) => {
            toast.success("All bets deleted successfully");
            handleGetData();
          },
          onError: (error) => {
            console.log(error);
            toast.error(error.message);
          },
        });
      }
    });
  }

  function deleteBet(betId) {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this Bet. You won't be able to revert it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@9ex") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          bettingId: betId,
          deletedBy: userCode,
        };

        deleteMatchBet(payload, {
          onSuccess: () => {
            toast.success("Bet deleted successfully");
            handleGetData();
          },
          onError: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  // function trashBet(bet) {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You want to delete this Bet. You won't be able to revert it.`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //     input: "text",
  //     inputLabel: "Enter your password",
  //     inputValidator: (value) => {
  //       if (!value) {
  //         return "Password is required!";
  //       }
  //       if (value !== "Tihor@9ex") {
  //         return "Invalid Password!";
  //       }
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const payload = {
  //         id: bet.id,
  //         match_id: bet.match_id,
  //         user_id: bet.user_id,
  //         deletedBy: userCode,
  //         trash: true,
  //       };

  //       editMatchBet(payload, {
  //         onSuccess: () => {
  //           toast.success("Bet deleted successfully");
  //           handleGetData();
  //         },
  //         onError: (error) => {
  //           console.log(error);
  //         },
  //       });
  //     }
  //   });
  // }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            {accountType === "admin" ? (
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleDeleteBets}>
                    <div className="row">
                      <div className="form-group col-md-3">
                        <label className="form-label">Users</label>
                        <Select
                          options={[
                            { value: "", label: "Select" },
                            { value: "all", label: "All" },
                            ...users.map((user) => ({
                              value: user.id,
                              label: `${user.code} - ${user.name}`,
                            })),
                          ]}
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={""}
                          isDisabled={false}
                          isLoading={users.length === 0 ? true : false}
                          isSearchable={true}
                          isRtl={false}
                          name="selectedUser"
                          onChange={(e) => {
                            const selected = e?.value;
                            setSelectedUser(selected);
                            changeUser(selected);
                          }}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <label className="form-label">On Date</label>
                        <input
                          type="date"
                          id="onDate"
                          name="onDate"
                          className="form-control"
                          onChange={(e) => {
                            setOnDate(e.target.value);
                          }}
                          required
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <label className="form-label" htmlFor="">
                          From Time
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            id="fromTime"
                            name="fromTime"
                            // className="form-control"
                            views={["hours", "minutes", "seconds"]}
                            onChange={(e) => {
                              const dateObj = new Date(e);
                              const time = dateObj.toLocaleTimeString("en", {
                                hour12: false,
                              }); // 24-hour format
                              console.log(time);
                              setFromTime(time);
                            }}
                            ampm={false}
                            required
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="form-group col-md-3">
                        <label className="form-label" htmlFor="">
                          To Time
                        </label>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            views={["hours", "minutes", "seconds"]}
                            ampm={false}
                            id="toTime"
                            name="toTime"
                            // className="form-control"
                            onChange={(e) => {
                              const dateObj = new Date(e);
                              const time = dateObj.toLocaleTimeString("en", {
                                hour12: false,
                              }); // 24-hour format
                              console.log(time);
                              setToTime(time);
                            }}
                            style={{ padding: "2px" }}
                            required
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="form-group col-md-3 mt-3">
                        <button type="submit" className="btn btn-danger ">
                          Delete Bets
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}

            <div className="card">
              {/* /.card-header */}
              <div className="card-header">
                <div className="table">
                  <div className="col-6">
                    <table className="table table-bordered align-middle">
                      <tbody style={{ color: "black" }}>
                        <tr style={{ height: "47px" }}>
                          <th>{teams?.team1}</th>
                          <td>{team1Total}</td>
                        </tr>
                        <tr style={{ height: "47px" }}>
                          <th>{teams?.team2}</th>
                          <td>{team2Total}</td>
                        </tr>
                        <tr>
                          <th>Client</th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <Select
                              options={[
                                { value: "", label: "Select" },
                                { value: "All", label: "All" },
                                ...users.map((user) => ({
                                  value: user.id,
                                  label: user.name,
                                })),
                              ]}
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={""}
                              isDisabled={false}
                              isLoading={users.length === 0 ? true : false}
                              isSearchable={true}
                              isRtl={false}
                              name="selectedUser"
                              onChange={(e) => {
                                const selected = e?.value;
                                setSelectedUser(selected);
                                changeUser(selected);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* /.card-header */}

              {/* /.card-body */}
              <div className="card-body">
                <div className="table-responsive">
                  <div className="col-md-12">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th style={{ padding: ".75rem" }}>SNO.</th>
                          <th style={{ padding: ".75rem" }}>Client</th>
                          <th style={{ padding: ".75rem" }}>Rate</th>
                          <th style={{ padding: ".75rem" }}>Team</th>
                          <th style={{ padding: ".75rem" }}>Mode</th>
                          <th style={{ padding: ".75rem" }}>Amount</th>
                          <th style={{ padding: ".75rem" }}>Date & Time</th>
                          <th style={{ padding: ".75rem" }}>{teams?.team1}</th>
                          <th style={{ padding: ".75rem" }}>{teams?.team2}</th>
                          {accountType === "admin" && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {matchBets?.length > 0 ? (
                          matchBets.map((bets, index) => (
                            <tr key={index}>
                              <td style={{ padding: ".75rem" }}>{index + 1}</td>
                              <td style={{ padding: ".75rem" }}>
                                {bets.user_accounts[0].name} (
                                {bets.user_accounts[0].code})
                              </td>

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamA_lay_rate
                                    : bets.teamA_back_rate}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamB_lay_rate
                                    : bets.teamB_back_rate}
                                </td>
                              )}

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {teams?.team1}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {teams?.team2}
                                </td>
                              )}

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>Khai</td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>Lagai</td>
                              )}

                              <td style={{ padding: ".75rem" }}>
                                {bets.amount}
                              </td>

                              <td
                                style={{ padding: ".75rem", minWidth: "160px" }}
                              >
                                {dateTimeFormatter(bets.createdAt)}
                              </td>

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamA_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamA_bet_amount)}
                                </td>
                              )}

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamB_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamB_bet_amount)}
                                </td>
                              )}

                              {accountType === "admin" ? (
                                <td style={{ padding: ".75rem" }}>
                                  <button
                                    className="btn btn-sm btn-primary btn-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteBet(bets.id);
                                      // trashBet(bets);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button>
                                </td>
                              ) : null}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={accountType === "admin" ? 10 : 9}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
