import React, { useEffect, useState } from "react";
import "./SubadminLimitUpdate.css";
import { Link, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import useGetChildren from "../../hooks/useGetChildren";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function SubAdminChildLimit() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();
  const location = useLocation();

  const userData = location.state;
  const [userId, setUserId] = useState(userData.id);
  const [userLimit, setUserLimit] = useState(userData.limit);

  const [data, setData] = useState([]);

  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { mutate: getChildren } = useGetChildren();
  const { mutate: updateLimit } = useUpdateLimit();

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetChildren = () => {
    const payload = {
      acc_type: "master",
      user_id: userId,
    };

    getChildren(payload, {
      onSuccess: (response) => {
        setData(response.data.data);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    });
  };

  useEffect(() => {
    if (userId) {
      handleGetChildren();
    }
  }, [userId]);

  const handleAddLimit = (id, masterLimit) => {
    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value >= userLimit) {
      toast.error(
        "You can not add balance greater than your Parent limit balance"
      );
      inputElement.value = "";
      return;
    }

    const masterLimitPayload = {
      user_id: id,
      acc_type: "master",
      limit: Number(masterLimit) + Number(value),
      coins: value,
      types: "credit",
      description: "Master Limit Update",
    };

    const parentLimitPayload = {
      user_id: userId,
      acc_type: "sub_admin",
      limit: Number(userLimit) - Number(value),
      coins: value,
      types: "debit",
      description: "Sub Admin Limit Update",
    };

    updateLimit(parentLimitPayload, {
      onSuccess: () => {
        updateLimit(masterLimitPayload, {
          onSuccess: () => {
            handleGetChildren();
            toast.success("Limit Added Successfully");

            inputElement.value = "";
            setUserLimit(Number(userLimit) - Number(value));
          },
          oonError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const handleMinusLimit = (id, masterLimit) => {
    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value === "" || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value > masterLimit) {
      toast.error("You can not minus balance greater than your limit balance");
      inputElement.value = "";
      return;
    }

    const masterLimitPayload = {
      user_id: id,
      acc_type: "master",
      limit: Number(masterLimit) - Number(value),
      coins: value,
      types: "debit",
      description: "Master Limit Update",
    };

    const parentLimitPayload = {
      user_id: userId,
      acc_type: "sub_admin",
      limit: Number(userLimit) + Number(value),
      coins: value,
      types: "credit",
      description: "Sub Admin Limit Update",
    };

    updateLimit(masterLimitPayload, {
      onSuccess: () => {
        updateLimit(parentLimitPayload, {
          onSuccess: () => {
            handleGetChildren();
            toast.success("Limit Minus Successfully");

            inputElement.value = "";
            setUserLimit(Number(userLimit) + Number(value));
          },
          oonError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data?.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(currentPage + 1 === Math.ceil(data?.length / itemsPerPage));
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Sub Admin</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/sub-admin">Sub Admin</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Child Limit Update</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content" style={{ overflowX: "auto" }}>
        <div className="container-fluid">
          <div className="col-12">
            <div className="card" style={{ width: "1200px" }}>
              <div className="card-header bg-primary">
                <h5 className="text-white mb-0">Master Coin Details</h5>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="column-parent-2">
                    <div
                      className="parent-container"
                      style={{ marginBottom: "10px" }}
                    >
                      <div className="col-6">
                        <div
                          className="label-1 dataTables_length"
                          id="example1_length"
                        >
                          <label>
                            Show
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                setItemsPerPage(e.target.value);
                              }}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                            entries
                          </label>
                        </div>
                      </div>
                      <div className="input-container col-6 d-flex gap-2 justify-content-end">
                        <label htmlFor="search">Search:</label>
                        <input
                          type="text"
                          id="search"
                          className="form-control form-control-sm w-auto ml-0"
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            if (value?.length > 0) {
                              searchFilter(value);
                            } else {
                              setData(completeData);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-striped"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th style={{ padding: ".75rem" }}>#</th>
                        <th
                          className="client-name-1"
                          style={{ padding: ".75rem" }}
                        >
                          Master Name
                        </th>
                        <th style={{ padding: ".75rem" }}>Master Limit</th>
                        <th style={{ width: "221px", padding: ".75rem" }}>
                          Enter Limit
                        </th>
                        <th style={{ padding: ".75rem" }}>
                          My Limit: {userLimit}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length === 0 && (
                        <tr>
                          <td colSpan={5}>No Data Found</td>
                        </tr>
                      )}
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td style={{ padding: ".75rem" }}>{index + 1}</td>
                          <td style={{ padding: ".75rem" }}>
                            {item.name} ({item.code})
                          </td>
                          <td style={{ padding: ".75rem" }}>{item.limit}</td>
                          <td style={{ padding: ".75rem" }}>
                            <input
                              type="number"
                              className="form-control child-limit-input-size"
                              placeholder="Enter Limit"
                              min="0"
                              id={item.id}
                            />
                          </td>
                          <td
                            className="action-btn-container"
                            style={{ padding: ".75rem" }}
                          >
                            <Link
                              className="btn btn-primary mr-2 action-btns action-button-plus-minus"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddLimit(item.id, item.limit);
                              }}
                            >
                              Add
                            </Link>
                            <Link
                              className="btn btn-danger action-btns"
                              onClick={(e) => {
                                e.preventDefault();
                                handleMinusLimit(item.id, item.limit);
                              }}
                            >
                              Minus
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-5">
                  <div className="dataTables_info" id="example1_info">
                    Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                    {completeData?.length} entries
                  </div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example1_paginate"
                  >
                    <ul className="pagination">
                      <li
                        className={`paginate_button page-item previous ${
                          isFirstPage ? "disabled" : ""
                        }`}
                        id="example1_previous"
                      >
                        <a
                          tabIndex="0"
                          className="page-link"
                          onClick={handlePrevious}
                        >
                          Previous
                        </a>
                      </li>
                      {Array.from({
                        length: Math.ceil(data?.length / itemsPerPage),
                      })?.map((_, index) => (
                        <li key={index} className="page-item">
                          <a
                            onClick={() => paginate(index + 1)}
                            className={`page-link ${
                              currentPage === index + 1
                                ? "active-page-link"
                                : ""
                            }`}
                          >
                            {index + 1}
                          </a>
                        </li>
                      ))}
                      <li
                        className={`paginate_button page-item next ${
                          isLastPage ? "disabled" : ""
                        }`}
                        id="example1_next"
                      >
                        <a
                          tabIndex="0"
                          className="page-link"
                          onClick={handleNext}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
