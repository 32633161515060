import React from "react";
import "./LedgerManagement/ledgermanagement.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useGetLedgerData from "../hooks/Ledger/useGetLedgerData";
import useGetAccountStatus from "../hooks/useGetAccountStatus";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

export default function Ledger(props) {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();

  const { mutate: getLedgerData } = useGetLedgerData();

  const [users, setUsers] = useState([]);
  const [ledger, setLedger] = useState([]);
  const [denaHai, setDenaHai] = useState([]);
  const [denaHaiComplete, setDenaHaiComplete] = useState([]);
  const [lenaHai, setLenaHai] = useState([]);
  const [lenaHaiComplete, setLenaHaiComplete] = useState([]);
  const [denaHaiTotal, setDenaHaiTotal] = useState(0);
  const [lenaHaiTotal, setLenaHaiTotal] = useState(0);

  const checkData = () => {
    const UUID = localStorage.getItem("UUID");
    const accountType = localStorage.getItem("account");

    const payload = {
      user_id: UUID,
      logged_acc_type: accountType,
      acc_type: "super_admin",
    };

    getLedgerData(payload, {
      onSuccess: (response) => {
        //console.log(response);
        //console.log(response.data.won);
        response.data.won.forEach((element) => {
          if (
            !users.some(
              (el) => el.super_admin_id === element.user_super_admins[0].id
            )
          ) {
            users.push({ super_admin_id: element.user_super_admins[0].id });
            ledger.push(element);
          } else {
            const index = ledger.findIndex(
              (img) =>
                img.user_super_admins[0].id === element.user_super_admins[0].id
            );
            ledger[index].super_admin_final =
              ledger[index].super_admin_final + element.super_admin_final;
          }
        });

        ledger.forEach((el) => {
          if (el.super_admin_final > 0) {
            let new_el = {
              ...el,
              super_admin_final: parseInt(el.super_admin_final),
            };

            lenaHai.push(new_el);
            lenaHaiComplete.push(new_el);
            setLenaHaiTotal(
              lenaHai.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.super_admin_final,
                0
              )
            );
          } else {
            let new_el = {
              ...el,
              super_admin_final: parseInt(Math.abs(el.super_admin_final)),
            };

            denaHai.push(new_el);
            denaHaiComplete.push(new_el);
            setDenaHaiTotal(
              denaHai.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.super_admin_final,
                0
              )
            );
          }
        });

        // console.log("users", users);
        // console.log("ledger", ledger);
        // console.log("denaHai", denaHai);
        // console.log("lenaHai", lenaHai);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    checkData();
  }, []);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function searchLenaHai(val) {
    const filteredData = lenaHai.filter((item) => {
      return (
        item.user_super_admins[0].code
          .toLowerCase()
          .includes(val.toLowerCase()) ||
        item.user_super_admins[0].name.toLowerCase().includes(val.toLowerCase())
      );
    });

    setLenaHai(filteredData);

    setLenaHaiTotal(
      filteredData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.super_admin_final,
        0
      )
    );
  }

  function searchDenaHai(val) {
    const filteredData = denaHai.filter((item) => {
      return (
        item.user_super_admins[0].code
          .toLowerCase()
          .includes(val.toLowerCase()) ||
        item.user_super_admins[0].name.toLowerCase().includes(val.toLowerCase())
      );
    });

    setDenaHai(filteredData);

    setDenaHaiTotal(
      filteredData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.super_admin_final,
        0
      )
    );
  }

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>All Client Ledger</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>All Client Ledger</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* <button onClick={checkData}>Check</button> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card p-2">
            <div className="row">
              <div className="col-md-6">
                <div class="d-flex align-items-center">
                  <span class="me-1">Search:</span>
                  <input
                    type="text"
                    class="form-control w-50 ml-1"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (value.length > 0) {
                        searchLenaHai(value);
                      } else {
                        setLenaHai(lenaHaiComplete);
                        setLenaHaiTotal(
                          lenaHaiComplete.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.super_admin_final,
                            0
                          )
                        );
                      }
                    }}
                  />
                </div>
                <table
                  className="table table-bordered table-striped"
                  id="datatable"
                >
                  <thead>
                    <tr>
                      <th colSpan={6}>PAYMENT RECEIVING FROM (LENA HE)</th>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lenaHai && lenaHai.length !== 0 ? (
                      <>
                        {lenaHai.map((el, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {el.user_super_admins[0].code}{" "}
                                {el.user_super_admins[0].name}
                              </td>
                              <td>{el.user_super_admins[0].mobile}</td>
                              <td>{el.super_admin_final}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={2}>Total</td>
                          <td>{lenaHaiTotal}</td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan={3}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <div class="d-flex align-items-center">
                  <span class="me-1">Search:</span>
                  <input
                    type="text"
                    class="form-control w-50 ml-1"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (value.length > 0) {
                        searchDenaHai(value);
                      } else {
                        setDenaHai(denaHaiComplete);
                        setDenaHaiTotal(
                          denaHaiComplete.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.super_admin_final,
                            0
                          )
                        );
                      }
                    }}
                  />
                </div>
                <table
                  className="table table-bordered table-striped"
                  id="datatable"
                >
                  <thead>
                    <tr>
                      <th colSpan={6}>PAYMENT RECEIVING FROM (DENA HE)</th>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {denaHai && denaHai.length !== 0 ? (
                      <>
                        {denaHai.map((el, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {el.user_super_admins[0].code}{" "}
                                {el.user_super_admins[0].name}
                              </td>
                              <td>{el.user_super_admins[0].mobile}</td>
                              <td>{el.super_admin_final}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={2}>Total</td>
                          <td>{denaHaiTotal}</td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan={3}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
