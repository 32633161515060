import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../Components/PageHeader";
import useUpdatePassword from "../hooks/useUpdatePassword";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./Profile.css";
import useGetAccountStatus from "../hooks/useGetAccountStatus";

export default function ChangePassword() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();

  const navigate = useNavigate();
  const { mutate: updatePassword } = useUpdatePassword();

  const userData = JSON.parse(localStorage.getItem("DATA"));
  const userAccount = localStorage.getItem("account");

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const userPassword = userData.password;
    const userId = userData.id;

    if (oldPassword !== userPassword) {
      toast.error("Old password is incorrect");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }

    if (confirmPassword.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    const payload = {
      user_id: userId,
      old_password: oldPassword,
      new_password: confirmPassword,
      type: userAccount,
    };

    updatePassword(payload, {
      onSuccess: (data) => {
        toast.success("Password changed successfully");
        navigate("/profile");
      },
      onError: (error) => {
        toast.error(error);
      },
    });
  }

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="content-wrapper">
      <PageHeader title="Change Password" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card upperCard passwordchangecardsize">
              <div className="container-fluid">
                <form onSubmit={handleSubmit}>
                  <div className="px-1 py-3 d-flex flex-column justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faLock} size="5x" />
                    <h1>Change Password?</h1>
                    <p>You can change your password here.</p>
                    <div className="form-group mb-2 changepasswordsize">
                      <label className="form-label">Current Password</label>
                      <div className="input-group">
                        <input
                          type={showOldPassword ? "text" : "password"}
                          id="oldPassword"
                          name="oldPassword"
                          className="form-control"
                          placeholder="Enter Old Password"
                          required
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                        />
                        <span
                          className="input-group-text btn btn-secondary"
                          onClick={() => {
                            setShowOldPassword(!showOldPassword);
                            setShowNewPassword(false);
                            setShowConfirmPassword(false);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={showOldPassword ? faEye : faEyeSlash}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="form-group mb-2 changepasswordsize">
                      <label className="form-label">New Password</label>
                      <div className="input-group">
                        <input
                          type={showNewPassword ? "text" : "password"}
                          id="newPassword"
                          name="newPassword"
                          className="form-control"
                          placeholder="Enter New Password"
                          required
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                        />
                        <span
                          className="input-group-text btn btn-secondary"
                          onClick={() => {
                            setShowNewPassword(!showNewPassword);
                            setShowOldPassword(false);
                            setShowConfirmPassword(false);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={showNewPassword ? faEye : faEyeSlash}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="form-group mb-2 changepasswordsize">
                      <label className="form-label">Confirm New Password</label>
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Enter Confirm Password"
                          required
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                        <span
                          className="input-group-text btn btn-secondary"
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                            setShowOldPassword(false);
                            setShowNewPassword(false);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEye : faEyeSlash}
                          />
                        </span>
                      </div>
                      <p className="text-in-change-password lower-low">
                        Must be at least 8 characters include numeric, alphabets
                        and special symbols i.e. Bharat@007#
                      </p>
                      <p className="text-in-change-password">
                        आपके अकाउंट की सुरक्षा को ध्यान रखते हुए आपके लिए
                        कंप्यूटर जनित पासवर्ड बनाया जा रहा है | नीचे दिए गए बटन
                        पर क्लिक करके एक नया पासवर्ड बनाएं|
                      </p>
                      <p className="text-in-change-password">
                        Keeping in mind the security of your account, a
                        computer-generated password is being created for you..
                        Create a new password by clicking on the button below
                      </p>
                    </div>
                    <div className="d-flex w-100 mt-2 justify-content-between">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/profile");
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Change Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
