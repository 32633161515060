/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./clientplusminus.css";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import PageHeader from "../Components/PageHeader";
import useGetCompleteMatch from "../hooks/Games/useGetCompleteMatch";
import { dateTimeFormatter } from "../utils/dateFormatter";
import useRevertMatch from "../hooks/Games/Complete/useRevertMatch";
import Swal from "sweetalert2";
import { useQueryClient } from "react-query";

export default function CompleteGames() {
  const queryClient = useQueryClient();
  const { data: completedGames } = useGetCompleteMatch();
  const { mutate: revertMatch } = useRevertMatch();

  const [data, setData] = useState([]);
  const accountType = localStorage.getItem("account");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    setData(completedGames?.data?.data || []);
  }, [completedGames]);

  const completeData = data || [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2
      );
    }
  };

  function undoGame(id) {
    console.log(id);

    Swal.fire({
      title: "Are you sure?",
      text: "You want to revert the game decision?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Revert it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const matchId = id;
        const payload = {
          match_id: matchId,
        };

        revertMatch(payload, {
          onSuccess: () => {
            toast.success("Game reverted successfully");
            queryClient.invalidateQueries("get-complete-match");
          },
          onError: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  const totalPages = Math.ceil(completeData?.length / itemsPerPage);
  const middlePagesToShow = 3;
  const pageNumbers = [];

  if (totalPages <= middlePagesToShow + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= Math.ceil(middlePagesToShow / 2) + 1) {
    for (let i = 1; i <= middlePagesToShow + 1; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - Math.floor(middlePagesToShow / 2)) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = totalPages - middlePagesToShow; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - Math.floor(middlePagesToShow / 2);
      i <= currentPage + Math.floor(middlePagesToShow / 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  return (
    <div className="content-wrapper">
      <PageHeader title="Complete Games" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title mb-0">Sports Details</h3>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr className="main-one">
                      <th className="first-one">#</th>
                      <th className="first-two">SNo</th>
                      <th className="first-three">Code</th>
                      <th className="first-four">Name</th>
                      <th className="first-five">Date Time</th>
                      <th className="first-six">Match Type</th>
                      <th className="first-seven">Declare</th>
                      <th className="first-eight">Won By</th>
                      <th className="first-nine">Plus Minus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length !== 0 ? (
                      currentItems.map((item, index) => (
                        <tr
                          key={index}
                          id={`row${item.code}`}
                          className="main-one"
                        >
                          <td>
                            <div className="btn-group">
                              <button
                                style={{
                                  padding: "4px 8px",
                                  lineHeight: "1.5",
                                }}
                                type="button"
                                className="btn btn-outline-primary bg-primary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span className="sr-only">Toggle Dropdown</span>
                              </button>
                              <div className="dropdown-menu" role="menu">
                                <Link
                                  className="dropdown-item"
                                  to={{
                                    pathname:
                                      "/complete-games/match-session-plus-minus",
                                  }}
                                  state={item}
                                >
                                  Match Session Plus Minus
                                </Link>

                                <Link
                                  className="dropdown-item"
                                  to={{
                                    pathname:
                                      "/complete-games/match-session-bets",
                                  }}
                                  state={item}
                                >
                                  Display Match & Session Bets
                                </Link>

                                <Link
                                  className="dropdown-item"
                                  to={`/complete-games/match-bets?id=${item.id}`}
                                  state={{
                                    team1: item.team1,
                                    team2: item.team2,
                                  }}
                                >
                                  Display Match Bets
                                </Link>

                                <Link
                                  className="dropdown-item"
                                  to={`/complete-games/session-bets?id=${item.id}`}
                                >
                                  Display Session Bets
                                </Link>

                                {accountType === "admin" ||
                                accountType === "power_user" ? (
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      undoGame(item.id);
                                    }}
                                  >
                                    Revert Game
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </td>

                          <td>{index + 1}</td>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                          <td>{dateTimeFormatter(item.server)}</td>
                          <td>{item.match_type}</td>
                          <td>YES</td>
                          <td>
                            {item.won_by === "A"
                              ? item.team1
                              : item.won_by === "B"
                              ? item.team2
                              : item.won_by === "DRAW"
                              ? "DRAW"
                              : "N/A"}
                          </td>
                          <td>
                            {/*}
                            {accountType === "admin" ||
                            accountType === "power_user" ? (
                              <button
                                className="btn btn-sm btn-primary btn-danger"
                                onClick={() => {
                                  undoGame(item.id);
                                }}
                              >
                                <i className="fa fa-undo">
                                  <FontAwesomeIcon icon={faUndo} />
                                </i>
                              </button>
                            ) : (
                              "N/A"
                            )}
                            */}
                            0
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center p-5">
                          <h6 className="p-5">No records to display</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData?.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            currentPage <= 1 ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(Math.ceil(data?.length / itemsPerPage))].map(
                          (_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber >= currentPage - 2 &&
                              pageNumber <= currentPage + 2
                            ) {
                              return (
                                <li key={index} className="page-item">
                                  <a
                                    onClick={() => paginate(pageNumber)}
                                    className={`page-link ${
                                      currentPage === pageNumber
                                        ? "active-page-link"
                                        : ""
                                    }`}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          }
                        )}

                        <li
                          className={`paginate_button page-item next ${
                            currentPage >= Math.ceil(data.length / itemsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
